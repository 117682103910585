import React, { useState } from "react";
import "../styles/Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      if (response.ok) {
        // Almacenar el token y el userId en localStorage
        localStorage.setItem("authToken", data.token);
        localStorage.setItem("userId", data.userId);

        // Verificamos que el token y el userId se guardaron correctamente
        console.log("Token guardado:", localStorage.getItem("authToken"));
        console.log("userId guardado:", localStorage.getItem("userId"));

        setIsLoggedIn(true);
      } else {
        setErrorMessage(data.message || "Credenciales inválidas");
      }
    } catch (error) {
      setErrorMessage("Error al intentar iniciar sesión. Inténtalo de nuevo más tarde.");
    }
  };

  const handleLogout = () => {
    // Limpiamos el localStorage y el estado de autenticación
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");

    console.log("Sesión cerrada, token y userId eliminados");

    setIsLoggedIn(false);
    setUsername("");
    setPassword("");
  };

  return (
    <div className="login-container">
      {isLoggedIn ? (
        <div className="logged-in">
          <h2>¡Sesión iniciada con éxito!</h2>
          <button onClick={handleLogout} className="logout-button">
            Cerrar sesión
          </button>
        </div>
      ) : (
        <form onSubmit={handleLogin}>
          <h2>Iniciar sesión</h2>
          <label htmlFor="username">Usuario</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Ingresa tu usuario"
            required
          />

          <label htmlFor="password">Contraseña</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Ingresa tu contraseña"
            required
          />

          <button type="submit" className="login-button">
            Iniciar sesión
          </button>

          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default Login;
