import { useState, useEffect } from "react";
import circuloImage from "../assets/images/IconoCarita/Icono-SoloCirculo.png";
import ojosImage from "../assets/images/IconoCarita/Icono-SoloOjos.png";
import bocaImage from "../assets/images/IconoCarita/Icono-SoloBoca.png";
import signoImagen from "../assets/images/IconoCarita/Icono-SoloInterrogacion.png";
import "../styles/EyesFollowingMouse.css";

export default function EyesFollowingMouse() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;

      const deltaX = event.clientX - centerX;
      const deltaY = event.clientY - centerY;

      const limitedX = Math.max(-5, Math.min(5, deltaX / 50));
      const limitedY = Math.max(-5, Math.min(5, deltaY / 50));

      setMousePosition({ x: limitedX, y: limitedY });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <div className="versito-container">
      {/* Círculo base fijo */}
      <div className="circle-container">
        <img src={circuloImage} alt="círculo base" className="circle-image" />
      </div>

      {/* signo */}
      <div className="signo-container">
        <img src={signoImagen} alt="signo" className="signo-image" />
      </div>

      {/* Ojos que siguen al mouse */}
      <div
        className="eyes-follower"
        style={{
          transform: `translate(calc(-58% + ${mousePosition.x}px), calc(-50% + ${mousePosition.y}px))`,
        }}
      >
        <img src={ojosImage} alt="ojos" className="eyes-image" />
      </div>

      {/* Boca con rotación */}
      <div className="mouth-container">
        <img src={bocaImage} alt="boca" className="mouth-image rotating" />
      </div>
    </div>
  );
}
