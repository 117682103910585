import React, { useState, useRef, useEffect } from "react";
import "../styles/MusicPlayer.css";

// Cargar el archivo de configuración (simulando con un JSON)
import musicConfig from "../config/musicFiles.json";

const MusicPlayer = ({ playOnGo }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isLooping, setIsLooping] = useState(false);
  const [musicFile, setMusicFile] = useState("");

  // Función para obtener un archivo de música aleatorio
  const getRandomMusicFile = () => {
    const randomIndex = Math.floor(Math.random() * musicConfig.musicFiles.length);
    return musicConfig.musicFiles[randomIndex];
  };

  useEffect(() => {
    // Seleccionamos un archivo de música aleatorio al montar el componente
    const selectedMusicFile = getRandomMusicFile();
    setMusicFile(selectedMusicFile);
  }, []);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const stopMusic = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;

    // Si el nuevo volumen es 0, activar mute
    if (newVolume === 0) {
      setIsMuted(true);
      audioRef.current.muted = true;
    } else {
      // Si el volumen es mayor a 0, desactivar mute
      setIsMuted(false);
      audioRef.current.muted = false;
    }

    // Actualizar el volumen y almacenarlo
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
    localStorage.setItem("volume", newVolume);
  };

  const toggleMute = () => {
    // Si se mutea, establecer volumen a 0
    if (!isMuted) {
      localStorage.setItem("lastVolume", audioRef.current.volume);
      setVolume(0);
      audioRef.current.volume = 0;
      audioRef.current.muted = true;
    } else {
      // Restaurar volumen guardado y desactivar mute
      const lastVolume = localStorage.getItem("lastVolume") || 0.25; // volumen por defecto
      setVolume(lastVolume);
      audioRef.current.volume = lastVolume;
      audioRef.current.muted = false;
    }

    setIsMuted(!isMuted);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleSeek = (event) => {
    const seekTime = event.target.value;
    audioRef.current.currentTime = seekTime;
  };

  const toggleLoop = () => {
    setIsLooping(!isLooping);
    audioRef.current.loop = !isLooping;
  };

  useEffect(() => {
    const savedVolume = localStorage.getItem("volume");
    if (savedVolume) {
      setVolume(savedVolume);
      audioRef.current.volume = savedVolume;
    } else {
      setVolume(0.25);
      audioRef.current.volume = 0.25;
    }

    audioRef.current.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  // New useEffect to handle playOnGo prop
  useEffect(() => {
    if (playOnGo && audioRef.current) {
      audioRef.current
        .play()
        .then(() => setIsPlaying(true))
        .catch((error) => console.error("Error playing audio:", error));
    }
  }, [playOnGo]);

  // New useEffect to pause music when the tab is not visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // Guardamos el volumen actual antes de bajarlo
        localStorage.setItem("previousVolume", audioRef.current.volume);
        audioRef.current.volume = 0;
      } else if (document.visibilityState === "visible") {
        // Restauramos el volumen al valor anterior cuando la pestaña es visible
        const previousVolume = localStorage.getItem("previousVolume") || 1; // Si no hay valor guardado, usamos 1
        audioRef.current.volume = previousVolume;
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <div className="music-player" aria-label="Reproductor de música">
      <audio
        ref={audioRef}
        src={`${process.env.PUBLIC_URL}/assets/music/${musicFile}`}
        onEnded={() => {
          setIsPlaying(false);
          if (isLooping) {
            audioRef.current.play();
          }
        }}
      />

      <div className="controls-buttons">
        <button
          onClick={togglePlay}
          aria-label={isPlaying ? "Pausar música" : "Reproducir música"}
        >
          {isPlaying ? (
            <i className="bi-pause"></i>
          ) : (
            <i className="bi-play"></i>
          )}
        </button>
        <button onClick={stopMusic} aria-label="Detener música">
          <i className="bi-stop"></i>
        </button>
        <button
          onClick={toggleMute}
          aria-label={isMuted ? "Activar sonido" : "Silenciar música"}
        >
          {isMuted ? (
            <i className="bi-volume-up-fill"></i>
          ) : (
            <i className="bi-volume-mute"></i>
          )}
        </button>
        <button
          onClick={toggleLoop}
          aria-label={isLooping ? "Desactivar bucle" : "Activar bucle"}
        >
          {isLooping ? (
            <i className="bi bi-chevron-bar-right"></i>
          ) : (
            <i className="bi bi-repeat"></i>
          )}
        </button>
      </div>

      <div className="controls-bar">
        <div className="volume-control">
          <i className="bi-volume-off"></i>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            aria-label="Control de volumen"
          />
          <i className="bi-volume-up"></i>
        </div>

        <div className="progress-control">
          <input
            type="range"
            min="0"
            max={audioRef.current?.duration || 0}
            step="0.1"
            value={currentTime}
            onChange={handleSeek}
            aria-label="Control de progreso"
          />
        </div>
      </div>
    </div>
  );
};

export default MusicPlayer;
