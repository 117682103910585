// ItemDisplayFinal.js
import React from "react";
import "../styles/ItemDisplayFinal.css"; // Asegúrate de tener un archivo CSS específico

function ItemDisplayFinal({ item }) {
  const imagePath = "/assets/images/user-uploaded/"; // Ajusta esta ruta según tu estructura de carpetas
  return (
    <div className="item-display-final">
      <h2>{item.it_title}</h2>
      <img src={`${imagePath}${item.it_photo}`} alt={item.it_title} />
      <p>{item.it_description}</p>
    </div>
  );
}

export default ItemDisplayFinal;
