import React, { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import "../styles/QRCodeGenerator.css"; // Importar el archivo CSS

const QRCodeGenerator = () => {
  // Estado para controlar la visibilidad del QR y la animación de salida
  const [isQRVisible, setIsQRVisible] = useState(false);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  // Obtener la URL actual del navegador
  const currentURL = window.location.href;

  const toggleQRVisibility = () => {
    if (isQRVisible) {
      // Si ya está visible, comenzamos la animación de salida
      setIsAnimatingOut(true);
      setTimeout(() => {
        setIsQRVisible(false);
        setIsAnimatingOut(false);
      }, 500); // Duración de la animación (debe coincidir con la animación CSS)
    } else {
      // Si no está visible, lo mostramos
      setIsQRVisible(true);
    }
  };

  return (
    <div className="code-container">
      <h3 className="code-heading">¡Compártelo y compite en esta categoría!</h3>
      <div className="code-icon" onClick={toggleQRVisibility}>
        <i
          className="bi bi-qr-code-scan"
          style={{ fontSize: "40px", cursor: "pointer" }}
        ></i>
      </div>
      {isQRVisible && (
        <div
          className={`code-code ${isAnimatingOut ? "fade-out" : ""}`}
          onClick={toggleQRVisibility}
        >
          <QRCodeCanvas
            value={currentURL}
            size={120} // Puedes ajustar el tamaño del QR aquí
            bgColor="#ffffff"
            fgColor="#000000"
          />
        </div>
      )}
    </div>
  );
};

export default QRCodeGenerator;
