import React, { useState, useEffect } from "react";
import MenuIcon from "./components/MenuIcon";
import Menu from "./components/Menu";
import ItemDisplay from "./components/ItemDisplay";
import ItemDisplayFinal from "./components/ItemDisplayFinal";
import "./App.css";
import "./styles/FondosStyles.css";

// Importación de imágenes de fondo
import imagenIzquierda from "./assets/images/imagen-izquierda.png";
import imagenDerecha from "./assets/images/imagen-derecha.png";
import vs from "./assets/images/vs.png";

// Importacion de cara al centro y ojos
import EyesFollowingMouse from "./components/EyesFollowingMouse";

// Importacion libreria para generar codigos QR
import QRCodeGenerator from "./components/QRCodeGenerator";

// Importacion de banner de publicidad
import Banner from "./components/Banner";

// Parametros
import configData from "./config/config.json"; // Asegúrate de que la ruta sea correcta

function App() {
  // Estados para el manejo del menú
  const [menuOpen, setMenuOpen] = useState(false);
  // Estado que indica si el usuario está logueado
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  // Estados para el manejo de ítems y comparaciones
  const [items, setItems] = useState([]);
  const [nextItems, setNextItems] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [comparisonResult, setComparisonResult] = useState(null);
  const [nextComparisonResult, setNextComparisonResult] = useState(null);

  // Estados para el manejo de carga y animaciones
  const [loadingBar, setLoadingBar] = useState(false);
  const [loadingWeb, setLoadingWeb] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [item1Animation, setItem1Animation] = useState("");
  const [item2Animation, setItem2Animation] = useState("");
  const [loadingItems, setLoadingItems] = useState(false);

  // Estados para el manejo de mensajes y votos
  const [voteMessageClass, setVoteMessageClass] = useState("");
  const [voteMessage, setVoteMessage] = useState("");
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [lado, setLado] = useState(null);

  // Estados para el manejo de audio y control de inicio
  const [audioVoz] = useState(new Audio());
  const [audioClic] = useState(new Audio());
  const [hasStarted, setHasStarted] = useState(false);
  const [playOnGo, setPlayOnGo] = useState(false);

  // Estado para el registro de depuración
  const [debugLog, setDebugLog] = useState([]);

  // Cambios en hashtags
  const [selectedHashtag, setSelectedHashtag] = useState(null);

  //spin de versus al apretar el boton GO
  const [isAnimating, setIsAnimating] = useState(false);

/* //lo comento porque parece que no se usa
  // Leemos el token del usuario
    useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setUserLoggedIn(true); 
    }
  }, []);
*/

  //********** Ini Lectura de parametrs desde Json **********//
  const [config, setConfig] = useState(null); // Inicializamos el estado como null

  useEffect(() => {
    try {
      setConfig(configData); // Cargamos la configuración directamente
    } catch (error) {
      console.error("Error loading config:", error);
    }
  }, []);

  // Desestructuración con manejo de null para evitar errores
  const {
    audioFiles = [],
    audioFelicitacionesFiles = [],
    animations1 = [],
    animations2 = [],
  } = config || {};
  //********** Fin Lectura de parametrs desde Json **********//

  /**
   * Función para agregar mensajes al registro de depuración
   * @param {string} message - Mensaje a agregar al registro
   */
  const addDebugLog = (message) => {
    console.log(message);
    setDebugLog((prev) => [
      ...prev,
      `${new Date().toISOString()} - ${message}`,
    ]);
  };

  /**
   * Manejador del botón "GO" para iniciar la aplicación
   */
  const handleGoClick = () => {
    setHasStarted(true);
    setPlayOnGo(true);
    setIsAnimating(true); // Activamos la animación
    audioClic.src = `/assets/soundeffects/Start.mp3`;
    audioClic
      .play()
      .catch((error) =>
        addDebugLog(`Error al reproducir el audio: ${error.message}`)
      );
  };

  /**
   * Función para alternar el estado del menú
   */
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    audioClic.src = `/assets/soundeffects/Menu.mp3`;
    audioClic
      .play()
      .catch((error) =>
        addDebugLog(`Error al reproducir el audio: ${error.message}`)
      );
  };

  /**
   * Efecto para manejar el cierre del menú con la tecla Escape
   */
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" && menuOpen) {
        setMenuOpen(false);
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [menuOpen]);

  /**
   * Obtiene una animación aleatoria para el primer ítem
   */
  const getRandomAnimation1 = () => {
    return animations1[Math.floor(Math.random() * animations1.length)];
  };

  /**
   * Obtiene una animación aleatoria para el segundo ítem
   */
  const getRandomAnimation2 = () => {
    return animations2[Math.floor(Math.random() * animations2.length)];
  };

  /**
   * Precarga una imagen
   * @param {string} url - URL de la imagen a precargar
   * @returns {Promise<boolean>} - Promesa que se resuelve con true si la carga fue exitosa
   */
  const preloadImage = (url) => {
    return new Promise((resolve) => {
      if (!url) {
        //addDebugLog(`URL de imagen inválida: ${url}`);
        resolve(false);
        return;
      }

      const img = new Image();
      img.onload = () => {
        //addDebugLog(`Imagen cargada exitosamente: ${url}`);
        resolve(true);
      };
      img.onerror = () => {
        addDebugLog(`Error al cargar imagen: ${url}`);
        resolve(false);
      };
      img.src = url;
    });
  };

  const [hasUrlHashtagSet, setHasUrlHashtagSet] = useState(false); // Estado adicional



  /**
   * Obtiene una comparación aleatoria del servidor
   * @returns {Promise<Object>} - Promesa que se resuelve con los datos de la comparación
   */
  const fetchComparison = async () => {
    try {
      // Determinamos el hashtag, si es necesario
      const hashtagParam = selectedHashtag
        ? `&hashtag=${encodeURIComponent(selectedHashtag)}`
        : hasUrlHashtagSet
        ? ""
        : (() => {
            let tagUrl = "";
            if (window.location.hash) {
              tagUrl = window.location.hash.substring(1);
            } else if (window.location.pathname.includes("/tag/")) {
              tagUrl = window.location.pathname.split("/tag/")[1];
            }
            return tagUrl ? `&hashtag=${encodeURIComponent(tagUrl)}` : "";
          })();
  
      console.log(`Fetching comparison with hashtag param: ${hashtagParam}`);
  
      // Obtener token y userId del localStorage
      const token = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
  
      // Verificamos si el usuario está logueado
      console.log("Token:", token);
      console.log("User ID:", userId);
  
      let comparisonResponse;
  
      // Si no hay token o userId, el usuario no está logueado
      if (!token || !userId) {
        console.log("Usuario no logueado, llamando a la API sin token...");
  
        // Realizamos la llamada a la API para usuarios no logueados
        comparisonResponse = await fetch(
          `${process.env.REACT_APP_API_HOST}/api/random-comparison?${hashtagParam}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        console.log("Usuario logueado, llamando a la API con token y userId...");
  
        // Realizamos la llamada a la API incluyendo el token y el userId
        comparisonResponse = await fetch(
          `${process.env.REACT_APP_API_HOST}/api/usuario/descartados-random-comparison?id_usuario=${userId}${hashtagParam}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Incluir el token en el encabezado
              "Content-Type": "application/json",
            },
          }
        );
      }
  
      if (!comparisonResponse.ok) {
        console.error(`Error en la respuesta de la API: ${comparisonResponse.status}`);
        throw new Error(`HTTP error! status: ${comparisonResponse.status}`);
      }
  
      const comparisonData = await comparisonResponse.json();
  
      console.log("Datos recibidos del API (comparación):", comparisonData);
        // Realizamos la segunda petición para obtener los ítems
      const itemsResponse = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/items?ids=${comparisonData.comp_item1_id},${comparisonData.comp_item2_id}`
      );
  
      if (!itemsResponse.ok) {
        console.error(`Error en la respuesta de la API (items): ${itemsResponse.status}`);
        throw new Error(`HTTP error! status: ${itemsResponse.status}`);
      }
  
      const itemsData = await itemsResponse.json();
  
      // Precargar imágenes
      const preloadResults = await Promise.all(
        itemsData.map((item) =>
          preloadImage(item.it_image_url).then((success) => ({
            url: item.it_image_url,
            success,
          }))
        )
      );
  
      return {
        items: itemsData,
        comparisonResult: {
          comp_item1_votes: comparisonData.comp_item1_votes,
          comp_item2_votes: comparisonData.comp_item2_votes,
          comp_total_votes: comparisonData.comp_total_votes,
          comp_id: comparisonData.comp_id,
        },
      };
    } catch (error) {
      addDebugLog(`Error en fetchComparison: ${error.message}`);
      throw error;
    }
  };
  
  



  /**
   * Obtiene y configura una nueva comparación aleatoria
   */
  const fetchRandomComparison = async () => {
    try {
      setLoadingWeb(true);
      //addDebugLog('Iniciando fetchRandomComparison');

      if (loadingItems) {
        if (nextItems && nextComparisonResult) {
          //addDebugLog('Usando elementos precargados');
          setItems(nextItems);
          setComparisonResult(nextComparisonResult);
          setItem1Animation(getRandomAnimation1());
          setItem2Animation(getRandomAnimation2());
        } else if (!initialLoadComplete) {
          //addDebugLog('Cargando elementos por primera vez');
          const currentData = await fetchComparison();
          setItems(currentData.items);
          setComparisonResult(currentData.comparisonResult);
          setItem1Animation(getRandomAnimation1());
          setItem2Animation(getRandomAnimation2());
          setInitialLoadComplete(true);
        }
      } else {
        //addDebugLog('Cargando elementos por primera vez');
        const currentData = await fetchComparison();
        setItems(currentData.items);
        setComparisonResult(currentData.comparisonResult);
        setItem1Animation(getRandomAnimation1());
        setItem2Animation(getRandomAnimation2());
        setInitialLoadComplete(true);
        setLoadingItems(false);
      }

      // Precargar siguiente conjunto de elementos
      //addDebugLog('Precargando siguiente conjunto de elementos');
      const nextData = await fetchComparison();
      setNextItems(nextData.items);
      setNextComparisonResult(nextData.comparisonResult);

      setInitialLoadComplete(true);
      //addDebugLog(`setInitialLoadComplete completado`);
    } catch (error) {
      addDebugLog(`Error en fetchRandomComparison: ${error.message}`);
    } finally {
      setLoadingWeb(false);
    }
  };

/**
 * Efecto para manejar cambios en el authToken
 */
useEffect(() => {
  // Reiniciar estado y cargar nuevos items
  setItems([]);
  setNextItems(null);
  setComparisonResult(null);
  setNextComparisonResult(null);
  setInitialLoadComplete(false);
  setLoadingItems(true);
  fetchRandomComparison();
}, [localStorage.getItem("authToken")]);  // El efecto se ejecuta cuando authToken cambie


  /**
   * Efecto para manejar cambios en el hashtag seleccionado
   */
  useEffect(() => {
    //addDebugLog('Efecto de selectedHashtag ejecutado');
    // Reiniciar estado y cargar nuevos items
    setItems([]);
    setNextItems(null);
    setComparisonResult(null);
    setNextComparisonResult(null);
    setInitialLoadComplete(false);
    setLoadingItems(true);
    fetchRandomComparison();
  }, [selectedHashtag]);

  /**
   * Actualiza los votos en el servidor
   * @param {string} selectedItemId - ID del ítem seleccionado
   */
  const handleVoteUpdate = async (selectedItemId) => {
    try {
      //addDebugLog(`Actualizando voto para ${selectedItemId}`);
      //const response = await fetch('http://localhost:5000/api/update-votes', {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/update-votes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            comparisonId: comparisonResult.comp_id,
            selectedItem: selectedItemId,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error);
      }
      //addDebugLog(`Voto actualizado exitosamente: ${data.message}`);
    } catch (error) {
      addDebugLog(`Error al actualizar el voto: ${error.message}`);
    }
  };

  /**
   * Maneja el clic en un ítem
   * @param {Object} item - Ítem seleccionado
   */
  const handleItemClick = async (item) => {
    if (loadingBar || !initialLoadComplete) return;

    // Reproducir audio de clic en boton
    audioClic.src = `/assets/soundeffects/Switch1.mp3`;
    audioClic
      .play()
      .catch((error) =>
        addDebugLog(`Error al reproducir el audio: ${error.message}`)
      );

    //addDebugLog(`Item clickeado: ${JSON.stringify(item)}`);
    setSelectedItem(item);
    setIsMessageVisible(false);

    const isFirstItemSelected = item.it_id === items[0].it_id;
    setLado(isFirstItemSelected ? 1 : 2);
    await handleVoteUpdate(isFirstItemSelected ? "item1" : "item2");

    // Reproducir audio después de 5 segundos
    const randomAudioIndex = Math.floor(Math.random() * audioFiles.length);
    const audioFelicitacionesFilesIndex = Math.floor(
      Math.random() * audioFelicitacionesFiles.length
    );

    audioVoz.src = `/assets/audios/${audioFelicitacionesFiles[audioFelicitacionesFilesIndex]}`;
    setTimeout(() => {
      audioVoz
        .play()
        .catch((error) =>
          addDebugLog(
            `Error al reproducir el audio de felicitaciones: ${error.message}`
          )
        );
    }, 50);

    setTimeout(() => {
      audioVoz.src = `/assets/audios/${audioFiles[randomAudioIndex]}`;
      audioVoz
        .play()
        .catch((error) =>
          addDebugLog(`Error al reproducir el audio: ${error.message}`)
        );
    }, 3000);

    // Calcular y mostrar mensaje de votación
    const { comp_item1_votes, comp_item2_votes } = comparisonResult;
    const selectedVotes = isFirstItemSelected
      ? comp_item1_votes
      : comp_item2_votes;
    const otherVotes = isFirstItemSelected
      ? comp_item2_votes
      : comp_item1_votes;

    let message = "";
    let messageClass = "";

    if (selectedVotes > otherVotes) {
      message = `¡Has elegido al más votado! (${(
        (selectedVotes / comparisonResult.comp_total_votes) *
        100
      ).toFixed(0)}%, ${selectedVotes} votos) + 1`;
      messageClass = "most-voted";
    } else if (selectedVotes < otherVotes) {
      message = `¡Has elegido al menos votado! (${(
        (selectedVotes / comparisonResult.comp_total_votes) *
        100
      ).toFixed(0)}%, ${selectedVotes} votos) +1`;
      messageClass = "least-voted";
    } else {
      message = `¡Genial! 🎉 Ahora hay un ganador gracias a tu voto! 🙌, Votos: ${
        selectedVotes + 1
      }`;
      messageClass = "tiebreaker";
    }

    setVoteMessage(message);
    setVoteMessageClass(messageClass);
    setLoadingBar(true);

    // Transición a nueva comparación después de 4 segundos
    setTimeout(async () => {
      //addDebugLog('Iniciando transición a nueva comparación');
      setIsMessageVisible(true);
      await fetchRandomComparison();

      // Esperar 1 segundo adicional
      await new Promise((resolve) => setTimeout(resolve, 500));
      setSelectedItem(null);
      setLoadingBar(false);
      setLoadingWeb(false);
    }, 2500);
  };

  /* funcion que ajustará la altura del body según el espacio visible real del navegador. (por si usa barras)*/
  useEffect(() => {
    // Función que ajusta la altura al tamaño visible del viewport
    function adjustHeight() {
      document.body.style.height = `${window.innerHeight}px`;
    }

    // Registrar eventos cuando el componente se monta
    window.addEventListener("resize", adjustHeight);
    window.addEventListener("load", adjustHeight);

    // Ajustar la altura en la primera carga
    adjustHeight();

    // Limpiar los eventos cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", adjustHeight);
      window.removeEventListener("load", adjustHeight);
    };
  }, []); // El array vacío asegura que se ejecute solo una vez al montar el componente

  /* para debug ver el tamaño de la pantalla */
  /*
  // Función para actualizar el tamaño de la pantalla
  function updateScreenSize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    document.getElementById('screen-size').innerText = `Tamaño: ${width}px x ${height}px`;
  }

  // Llamar la función cuando la página cargue
  window.onload = updateScreenSize;

  // Llamar la función cuando cambie el tamaño de la ventana
  window.onresize = updateScreenSize;
*/

  return (
    <div className="App">
      <div className="fondos-container">
        {/* Contenedor de fondos */}
        <div className="background-container">
          {/* Fondo izquierdo */}
          <div
            className="left-background"
            style={{ backgroundImage: `url(${imagenIzquierda})` }}
          ></div>

          {/* Fondo derecho */}
          <div
            className="right-background"
            style={{ backgroundImage: `url(${imagenDerecha})` }}
          ></div>
        </div>
      </div>

      <div className="header-container">
        <h2>¿Cuál elegirías?</h2>
        <h4 className="header-h4">Early-Access</h4>
        <div>{selectedHashtag ? selectedHashtag : ""}</div>
        {comparisonResult && (
          <p className="header-TotalVotos">
            Total de votos en este v/s: {comparisonResult.comp_total_votes}
          </p>
        )}
        <div className="qr-selectedHashtag">
          {/* Aquí se usa el componente QRCodeGenerator */}
          <QRCodeGenerator />
        </div>
      </div>
      {/* Contenedor de contenido central */}
      <div className="contenido-container">
        {/* Item 1 */}
        {!loadingWeb && items[0] && (
          <div
            className={`item item1 animate-subtle-movement-19  ${item1Animation} ${
              selectedItem === items[0] ? "selected" : ""
            }`}
            onClick={() => handleItemClick(items[0])}
          >
            <ItemDisplay item={items[0]} />
          </div>
        )}

        <div className={`vs-imagen ${isAnimating ? "active" : ""}`}>
          <img src={vs} alt="VS" />
        </div>

        {/* Item 2 */}
        {!loadingWeb && items[1] && (
          <div
            className={`item item2 animate-subtle-movement-3  ${item2Animation} ${
              selectedItem === items[1] ? "selected" : ""
            }`}
            onClick={() => handleItemClick(items[1])}
          >
            <ItemDisplay item={items[1]} />
          </div>
        )}
      </div>

      {selectedItem && (
        <>
          <div className="overlay" />
          {lado === 1 && <div className="vote-count left">+1</div>}
          {lado === 2 && <div className="vote-count right">+1</div>}

          <div className="centered-item">
            <ItemDisplayFinal item={selectedItem} />
            <p
              className={`mensaje-eleccion-votos ${voteMessageClass} ${
                isMessageVisible ? "show" : ""
              }`}
            >
              {voteMessage}
            </p>
          </div>
          {/*Esto deberia convertirse en un componente*/}
          {loadingBar && (
            <div className="loading-popup">
              <p className="mensaje-cargando">Cargando nueva comparación...</p>
              <div className="progress-bar">
                <div className="progress"></div>
              </div>
            </div>
          )}
        </>
      )}

      {/* boton de menu */}
      <MenuIcon toggleMenu={toggleMenu} />
      <Menu
        isOpen={menuOpen}
        toggleMenu={toggleMenu}
        setSelectedHashtag={setSelectedHashtag}
        playOnGo={playOnGo}
      />

      {/* Carga de web inicial, lo que sale en la parte inferior derecha */}
      {loadingWeb && !selectedItem && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Cargando...</p>
        </div>
      )}

      {/* Boton go al comienzo */}
      {!hasStarted && (
        <div className="go-button-overlay">
          {!loadingWeb && (
            <button className="go-button" onClick={handleGoClick}>
              GO!
            </button>
          )}
        </div>
      )}

      {/* Banner de publicidad como footer */}
      <div className="Banner-container">
        <Banner />
      </div>

      {process.env.DEBUG_EN_WEB === "True" && (
        <div className="eyes-following">
          <EyesFollowingMouse />
        </div>
      )}

      {process.env.DEBUG_EN_WEB === "True" && (
        <div id="screen-size">Tamaño: </div>
      )}
      {process.env.DEBUG_EN_WEB === "True" && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            maxHeight: "200px",
            overflowY: "auto",
            backgroundColor: "rgba(0,0,0,0.8)",
            color: "white",
            padding: "10px",
            fontSize: "12px",
            zIndex: 9999,
            width: "300px",
          }}
        >
          <h4>Debug Log:</h4>
          {debugLog.map((log, index) => (
            <div
              key={index}
              style={{
                marginBottom: "5px",
                borderBottom: "1px solid rgba(255,255,255,0.2)",
              }}
            >
              {log}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default App;
