import React, { useState, useEffect } from "react";
import MusicPlayer from "./MusicPlayer";
import "../styles/Menu.css";
import Login from './Login';


const Menu = ({ isOpen, toggleMenu, setSelectedHashtag, playOnGo }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
  const [selectedHashtag, setSelectedHashtagState] = useState(null);
  const [hasUrlHashtagSet, setHasUrlHashtagSet] = useState(false); // Estado adicional

  useEffect(() => {
    // Función para manejar los cambios en la URL
    const handleUrlChange = () => {
      console.log("URL completa:", window.location);
      let tagUrl = "";

      // Capturar el hash si la URL contiene "#"
      if (window.location.hash) {
        tagUrl = window.location.hash.substring(1); // Remueve el "#" del hash
        // Capturar el "tag" si la URL tiene la ruta "/tag/"
      } else if (window.location.pathname.includes("/tag/")) {
        // Extraer el tag después de "/tag/"
        tagUrl = window.location.pathname.split("/tag/")[1];
      }

      // Asignar el valor de tagUrl al estado si existe y aún no se ha establecido
      if (tagUrl && !hasUrlHashtagSet) {
        // Agrega el símbolo '#' si no está presente
        if (!tagUrl.startsWith("#")) {
          tagUrl = "#" + tagUrl;
        }
        setSearchQuery(tagUrl); // Establecer el hashtag en la barra de búsqueda
        //handleHashtagSelect(tagUrl); // Seleccionar el hashtag directamente
        console.log("Tag actual:", tagUrl);
        setHasUrlHashtagSet(true); // Marcar que ya se ha establecido el hashtag desde la URL
      }
    };

    // Ejecutar la función una vez al montar el componente para capturar el valor inicial
    handleUrlChange();

    // Agregar un event listener para cuando cambie el hash
    window.addEventListener("hashchange", handleUrlChange);

    // Limpieza del event listener al desmontar el componente
    return () => {
      window.removeEventListener("hashchange", handleUrlChange);
    };
  }, [hasUrlHashtagSet]); // Ahora dependemos de hasUrlHashtagSet

  const handleMenuClick = (e) => {
    e.stopPropagation(); // Prevenir que el clic dentro del menú cierre el menú
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 0) {
      // Llamar a la API para buscar hashtags
      // fetch(`http://localhost:5000/api/hashtags/search?q=${query}`)
      fetch(`${process.env.REACT_APP_API_HOST}/api/hashtags/search?q=${query}`)
        .then((response) => {
          if (!response.ok) {
            return response.text().then((text) => {
              console.error("Error del servidor:", text);
              throw new Error("Fallo al obtener hashtags");
            });
          }
          return response.json();
        })
        .then((data) => {
          console.log("Hashtags encontrados:", data);
          setHashtagSuggestions(data.slice(0, 5));
        })
        .catch((error) => {
          console.error("Error en la búsqueda de hashtags:", error);
          setHashtagSuggestions([]);
        });
    } else {
      setHashtagSuggestions([]);
    }
  };

  const handleHashtagSelect = (hashtag) => {
    const tagUrl = hashtag.startsWith("#") ? hashtag.substring(1) : hashtag; // Remueve '#' si está presente
    const newUrl = `/tag/${tagUrl}`; // Nueva URL basada en el hashtag seleccionado

    setSelectedHashtagState(hashtag); // Almacena el hashtag seleccionado
    setSearchQuery(hashtag); // Establecer el hashtag en la barra de búsqueda
    setHashtagSuggestions([]); // Limpiar las sugerencias
    setSelectedHashtag(hashtag); // Actualiza el hashtag seleccionado en el componente App

    // Actualizar la URL del navegador
    window.history.pushState({}, "", newUrl); // Actualiza la URL sin recargar la página

    console.log("Hashtag seleccionado:", hashtag); // Registro para verificar el hashtag seleccionado
  };

  const handleHashtagRemove = () => {
    setSelectedHashtagState(null); // Limpiar el hashtag seleccionado
    setSearchQuery(""); // Limpiar el campo de búsqueda
    setSelectedHashtag(null); // Limpiar el hashtag en el componente App

    // Actualizar la URL para eliminar el tag
    window.history.pushState({}, "", "/"); // Volver a la URL base sin tag
  };

  return (
    <div className={`menu ${isOpen ? "open" : "closed"}`} onClick={toggleMenu}>
      <div className="menu-content" onClick={handleMenuClick}>
        <p className="menu-titulo">Menú de opciones</p>

        {/* Mostrar el hashtag seleccionado */}
        {selectedHashtag && (
          <div className="selected-hashtag">
            <span>{selectedHashtag}</span>
            <button onClick={handleHashtagRemove}>Eliminar</button>
          </div>
        )}

        {/* Campo de búsqueda */}
        <input
          type="text"
          placeholder="Buscar hashtags..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="hashtag-search"
        />

        {/* Mostrar sugerencias */}
        {hashtagSuggestions.length > 0 && (
          <ul className="suggestions-list">
            {hashtagSuggestions.map((hashtag) => (
              <li
                key={hashtag.hash_id}
                onClick={() => handleHashtagSelect(hashtag.hash_tag)} // Maneja la selección del hashtag
              >
                {hashtag.hash_tag}
              </li>
            ))}
          </ul>
        )}

        <div>
          <Login />
        </div>


        <div className="Reproductor">
          <MusicPlayer playOnGo={playOnGo} />
        </div>
      </div>
    </div>
  );
};

export default Menu;
