import React, { useEffect, useState } from "react";
import "../styles/Banner.css";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    // Cargar el archivo JSON
    const fetchBanners = async () => {
      const response = await fetch("/config/bannersConfig.json"); // Asegúrate de que la ruta sea correcta
      const data = await response.json();
      setBanners(data);
    };

    fetchBanners();

    // Cambiar el banner cada 10 segundos
    const interval = setInterval(() => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 10000);

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar
  }, [banners.length]);

  if (banners.length === 0) {
    return <div className="banner">Cargando...</div>; // Mostrar mensaje mientras se cargan los banners
  }

  const currentBanner = banners[currentBannerIndex];

  return (
    <div className="banner">
      <div className="banner-image">
        {currentBanner.image && <img src={currentBanner.image} alt="Banner" />}
      </div>
      <div className="banner-text">
        <p>{currentBanner.text}</p>
        <a href={currentBanner.link} className="contact-link">
          Contacta aquí
        </a>
      </div>
    </div>
  );
};

export default Banner;
