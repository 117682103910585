// src/components/MenuIcon.js
import React from "react";
import "../styles/MenuIcon.css"; // Estilos específicos del ícono

const MenuIcon = ({ toggleMenu }) => {
  return (
    <div className="menu-icon" onClick={toggleMenu}>
      <i className="bi bi-menu-button"></i>
    </div>
  );
};

export default MenuIcon;
